import React, { useEffect, useState } from 'react';
import {Alert, Card, Col, Row} from 'react-bootstrap';
import TopScoresGraph from '../Components/TopScoresGraph';
import { TopLocationsGraph } from '../Components/TopLocationsGraph';
import PicksMap from '../Components/PicksMap';
import TopStandings from '../Components/TopStandings';
import TodaysPicksTable from '../Components/TodaysPicks';
import DatePlayerScoresTable from '../Components/DatePlayerScoresTable';
import YesterdayTopLocationScores from '../Components/YesterdayTopLocationScores';
import { Link } from 'react-router-dom';
import SEO from '../Components/Seo';
import { useWindowDimension } from '../Hooks/useWindowDimension';
import Tournament from '../Components/Tournament';

export default function Home() {

    const [pageData, setPageData] = useState({});
    const [picksTotal, setPicksTotal] = useState(-1);
    const [yesterdayPicksTotal, setYesterdayPicksTotal] = useState(-1);
    const [width] = useWindowDimension();

    useEffect(() => {

        fetch("page/home")
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setPageData(data);
            })
            .catch(() => {
                
            })
            .finally(() => {

            });
    }, []);  
    
    function determineGraphCount(){

        let limit = 10;

        if(width < 500)
        {
            limit = 5;
        } 
        
        return limit;
    }

    function getHeavySnowStringSeparator(heavySnowAlerts, index){
        
        if(heavySnowAlerts.length > 0 && heavySnowAlerts.length - 1 != index){
            
            if(heavySnowAlerts.length == 2){
                if(index == 0){
                    return " and "
                }
            }
            else if(heavySnowAlerts.length > 2){
                if(heavySnowAlerts.length - index > 2 ){
                    return ", ";
                }
                else if(heavySnowAlerts.length - index == 2){
                    return ", and ";
                }
            }
        }
        else{
            return "";
        } 
    }

    function GetHeavySnowAlerts(heavySnowAlerts){

        return heavySnowAlerts.map((x, index) => (
            <><Link key={index} className="table-link" to={"/location/" + x.stationIdentifier + "/" + x.name} title={x.name}>{x.displayName}
            
            </Link><span>{getHeavySnowStringSeparator(heavySnowAlerts, index)}</span></>
        ))    
    }

    setTimeout(() =>{
        window.location.reload();
    }, 300000);


  return (

    <>
        <SEO 
            title="FantasyBlizzard.com -- Home" 
            description="Fantasy Blizzard is a free and fun winter weather forecasting game to determine who can best predict heavy winter weather and blizzards in the United States" 
            crawl={true} 
        />

        {pageData.HeavySnowAlerts && <Row className='mt-3'>
              <Col sm="12">
                  <Alert key="1" variant="danger">
                      <strong>🚨❄️ Heavy snow is currently being reported in {GetHeavySnowAlerts(pageData.HeavySnowAlerts)} 🚨❄️</strong>
                  </Alert>
              </Col>
          </Row>
        }

        {pageData.PageMessage && <Row className='mt-3'>
              <Col sm="12">
                  <Alert key="1" variant="info">
                      <strong>{new Date(pageData.PageMessage.date).toLocaleDateString()}:</strong> {pageData.PageMessage.message}
                  </Alert>
              </Col>
          </Row>
        }

        <Row className="mb-3">
            <Col>
                <Card>
                    <Card.Header className="bg-dark text-white">Discord Server (beta)</Card.Header>
                    <Card.Body>
                        <p>We have fired up a Discord server for everything and anything Fantasy Blizzard related. Is there a 5 day lock you nailed and want to brag about or perhaps need to throw a challenge flag on a score that was posted, then please consider joining our server using the link below!</p>
                        <a href="https://discord.gg/Mae8zsBnz6" target='_blank' rel="noreferrer">Fantasy Blizzard Discord</a>
                    </Card.Body>                      
                </Card>
            </Col>
        </Row>  

        {pageData.ShowTournament && <Row className="mb-3">
            <Col sm="12">
                <Card style={{ minHeightheight: '410px' }}>
                    <Card.Header className="bg-dark text-white">March Mammatus Tournament</Card.Header>
                    <Card.Body>
                        <Tournament />
                    </Card.Body>
                </Card>
            </Col>
        </Row>}

        <Row>
              <Col md="12" lg="8">
                <Row className="mb-3">
                    <Col>
                        <Card style={{ height: '410px' }}>
                            <Card.Header className="bg-dark text-white">Today&apos;s Pick Map</Card.Header>
                            <Card.Body>
                                <PicksMap />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>                  
            </Col>
              <Col md="12" lg="4">
                <Row className="mb-3">
                    <Col>
                        <Card style={{ height: '410px' }}>
                            <Card.Header className="bg-dark text-white">{pageData.CurrentStandingsSeason !== undefined  ? pageData.CurrentStandingsSeason.name + " -- Top 10": <span>&nbsp;</span>}</Card.Header>
                            <Card.Body>
                                <TopStandings />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                  
            </Col>
        </Row>
        <Row>
            <Col sm="12" lg="6">     
                <Row className="mb-3">
                    <Col>
                        <TopScoresGraph count="5" />                    
                    </Col>
                </Row>
            </Col>
            <Col sm="12" lg="6">
                <Row className="mb-3">
                    <Col>
                        <TopLocationsGraph count={determineGraphCount()} />
                    </Col>
                </Row>
            </Col>        
        </Row>   
        <Row className="mb-3">
              <Col sm="12">
                  <Card style={{ minHeight: '100px' }}>
                      <Card.Header className="bg-dark text-white">Today&apos;s Picks {picksTotal >= 0 ? "(" + picksTotal + ")" : ""}</Card.Header>
                      <Card.Body>
                          <TodaysPicksTable setPicksTotal={setPicksTotal} />
                      </Card.Body>                      
                  </Card>                
            </Col>
        </Row>   
        <Row className="mb-3">
            <Col sm="12">                  
                <Card style={{ minHeight: '100px' }}>
                      <Card.Header className="bg-dark text-white">Yesterday&apos;s Official Scores {yesterdayPicksTotal >= 0 ? "(" + yesterdayPicksTotal + ")" : ""}</Card.Header>
                    <Card.Body>
                        <DatePlayerScoresTable date={pageData.Yesterday} text="yesterday" setPicksTotal={setYesterdayPicksTotal} />
                    </Card.Body>                      
                </Card>
            </Col>
        </Row>  
        <Row className="mb-3">
            <Col sm="12">
                <Card style={{ minHeight: '100px' }}>
                    <Card.Header className="bg-dark text-white">Yesterday&apos;s Top 10 Locations</Card.Header>
                    <Card.Body>
                        <YesterdayTopLocationScores count={10} sortDisabled={true}/>                        
                    </Card.Body>   

                    <footer className="card-footer text-right">
                        <Link className="text-decoration-none text-dark" to="/yesterday-scores">View All Scores</Link>
                    </footer>                   
                </Card>
            </Col>
        </Row>    
        <Row className="mb-3">
            <Col>
                <Card style={{ minHeight: '100px' }}>
                    <Card.Header className="bg-dark text-white">FantasyBlizzard.com</Card.Header>
                    <Card.Body>
                        <p>Fantasy Blizzard is a FREE and fun winter weather forecasting game aimed at predicting the three main components of heavy winter weather; snow, wind, and cold. Whether you&apos;re a professional meteorologist or just someone who likes weather and the thrill of competition, this game is for you.</p>
                        <p className="mb-0">The regular season runs from December 1st to February 28th, with a March Mammatus tournment taking place beginning March 1st for the top 32 seeded players. For more information on the game and its rules, please check out our <Link to="/about">About</Link> page.</p>
                    </Card.Body>                      
                </Card>
            </Col>
        </Row>  


    </>
  )
}