import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

function Footer(){
    return (
    <Container>
        <footer className="page-footer font-small blue pt-4">
            <div className="footer-copyright text-center py-3">© {new Date().getFullYear()}&nbsp;  
                <a className="text-decoration-none text-dark" href="https://www.fantasyblizzard.com/">FantasyBlizzard.com</a>
                &nbsp;|&nbsp;
                <Link className="text-decoration-none text-dark" to="/past-seasons">Past Seasons</Link>
                &nbsp;|&nbsp;
                <Link className="text-decoration-none text-dark" to="/missing-scores">Missing Scores</Link>
                &nbsp;|&nbsp;
                <Link className="text-decoration-none text-dark" to="/contact">Contact Us</Link>
                &nbsp;|&nbsp;
                <a className="text-decoration-none text-dark" href="https://www.twitter.com/FantasyBlizzard" target="_blank" rel="noreferrer">Twitter</a>
                &nbsp;|&nbsp;
                <a className="text-decoration-none text-dark" href="https://discord.gg/VnVk4QGc" target="_blank" rel="noreferrer">Join Our Discord</a>
            </div>

        </footer>
    </Container>
    )
}

export default Footer;